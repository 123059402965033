import { auth } from 'index'
/**
 * Wraps `fetch` to pass in Firebase Auth info.
 * `params` will be a JSON object sent as query params for GET requests
 * or as a stringified JSON text body for POST requests.
 */
export const makeApiRequest = async (endpoint, params) => {
  const url = `https://api.prod.moonrise.us/${endpoint}`

  const token = await (auth.currentUser?.getIdToken().catch(e => '')) ?? ''
  const response = await fetch(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(params)
    }
  ).catch(e => { return new Response() })
  return response
}

export const completeImageUrl = async (url) => {
  const authToken = await auth.currentUser?.getIdToken()
  return `${url}?token=${authToken}`
}

import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './common/reportWebVitals'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { Grommet } from 'grommet'

import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from 'react-toasts'

import { initializeApp } from 'firebase/app'

import './index.css'
import theme from './common/theme'

import Landing from 'Landing'
import Browse from 'Browse'
import Settings from 'Settings'
import { getAuth } from 'firebase/auth'

import { UserProvider } from 'common/UserContext'
import Profile from 'Profile'

const firebaseConfig = {
  apiKey: 'AIzaSyBAzlzbJ2Mr0VnUhpXZnunJxdSruKdb37s',
  authDomain: 'moonrise-prod-backend.firebaseapp.com',
  projectId: 'moonrise-prod-backend',
  storageBucket: 'moonrise-prod-backend.appspot.com',
  messagingSenderId: '874012850598',
  appId: '1:874012850598:web:b7414639ca5d13cc7d3767',
  measurementId: 'G-ZHCPQ8KVXN'
}

export const firebaseApp = initializeApp(firebaseConfig)
export const auth = getAuth(firebaseApp)

ReactDOM.render(
  <UserProvider>
    <Grommet theme={theme} full>
      <Router>
        <Routes>
          <Route path='/browse' element={<Browse />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/profile/:userId' element={<Profile />} />
          <Route path='/' element={<Landing />} />
        </Routes>
      </Router>

      <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.BOTTOM_CENTER}
      />
    </Grommet>
  </UserProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

export const copyright = '© Moonrise Technologies, Inc.'
export const buckleaf = 'Buckleaf'
export const tagline = 'Pansophy is an AI content moderation tool for proactively and continuously moderating user generated content on your platform.'
export const about = 'Pansophy was built to automate content moderation at '
export const freeTrial = 'Moonrise'
export const aboutContinued = '. We\'re currently beta testing our AI engine with Moonrise\'s user-generated content. Check back here soon for a link to our natural-language rule enforcing Discord AI moderator bot.'
export const dontHaveAnAccount = 'Don\'t have an account?'
export const signUpHere = 'Sign up here'
export const signUp = 'Sign Up'
export const forgot = 'Forgot password'
export const login = 'Login'
export const emailMissing = 'Please enter your email address'
export const emailInvalid = 'Please enter a valid email address'
export const passwordMissing = 'Please enter your password'
export const nameMissing = 'Please enter your name'
export const confirmPasswordMissing = 'Please enter your password again to confirm'
export const passwordsDoNotMatch = 'Passwords don\'t match'
export const email = 'Email'
export const password = 'Password'
export const currentPassword = 'Current password'
export const newPassword = 'New password'
export const confirmNewPassword = 'Confirm new password'
export const userNotFound = 'User account not found'
export const wrongPassword = 'Incorrect password'
export const genericError = 'Something went wrong...try again later'
export const passwordResetEmailSent = 'We\'ve emailed you password reset instructions'
export const searchPlaceholder = 'Search for user content'
export const searchWithinBookPlaceholder = 'Find text in book'
export const read = 'Read'
export const name = 'Name'
export const confirmPassword = 'Confirm password'
export const signupHeaderText = 'We\'ll make this quick. You\'ll be able to stream textbooks in no time!'
export const signupHeaderText2 = 'You\'re signing up for a free account now. You can upgrade to Buckleaf Premium later if you choose.'
export const next = 'Next'
export const nameChangeSettingHeaderText = 'Name'
export const saveChanges = 'Save Changes'
export const nameUpdatedMessage = 'Name updated'
export const emailUpdatedMessage = 'Email updated'
export const passwordUpdated = 'Password updated'
export const emailChangeSettingHeaderText = 'Email'
export const emailChangeSettingFooterText = 'We ask for your current password to re-authenticate you before updating sensitive data like your email.'
export const passwordChangeSettingFooterText = 'We ask for your current password to re-authenticate you before updating sensitive data like your password.'
export const passwordChangeSettingHeaderText = 'Password'
export const billingSettingHeaderText = 'Buckleaf Premium'
export const aboutCustomerPortal = 'You can cancel, renew or start your subscription from our Stripe-powered billing portal.'
export const customerPortalButtonText = 'Billing Portal'
export const billingSettingsFreeTrialText = 'Hope you\'re enjoying your free trial!'
export const billingSettingsActiveText = 'Your subscription is active.'
export const billingSettingsInactiveText = 'You don\'t have an active subscription.'
export const inactiveSubscriptionWarningCardHeader = 'Can\'t find something? Get Buckleaf Premium!'
export const inactiveSubscriptionWarningCardBody1 = 'We'
export const guarantee = 'guarantee'
export const inactiveSubscriptionWarningCardBody2 = 'that we\'ll find whatever book you want in time for the Spring 2022 semester.'
export const getStarted = 'Get started'
export const getPremium = 'Get Buckleaf Premium'
export const premium = 'Buckleaf Premium'
export const requestTitles = 'Request Titles'
export const nothingHereYet = 'Hmm...couldn\'t find whatever you were looking for'
export const addBooksToYourLibrary = 'No user generated content found'
export const noSearchResults = 'That doesn\'t ring a bell...'
export const noSearchResultsDetail = 'Try a different query, maybe?'
export const findNotesAndHighlightsPlaceholder = 'Search for note or highlight'
export const highlightOnPage = 'Highlight on Page'
export const noteOnPage = 'Note on Page'
export const newNoteOnPage = 'New Note on Page'
export const saveNote = 'Save Note'
export const saveHighlight = 'Save Highlight'
export const cancel = 'Cancel'
export const newNotePlaceholder = pageNumber => 'My thoughts on page ' + pageNumber + ' of the book are...'
export const newHighlightPlaceholder = 'My thoughts on this highlight are...'
export const selectTextToHighlightError = 'No text selected to highlight'
export const noAnnotationsCardBody = 'You have not taken any notes or highlighted any text in this book yet.'
export const noAnnotationsCardBodySearchMode = 'No notes or highlights match your search query.'
export const noAnnotationsCardTitle = 'No Annotations'
export const searchTextInBookPlaceholder = 'Search for text in book'
export const previous = 'Previous'
export const contentsTitle = 'Contents'
export const noContentsFounds = 'No embedded table of contents found in this book'
export const close = 'Close'
export const bookStillLoading = 'Your book is still loading.'
export const free = 'Free'

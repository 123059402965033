import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Text,
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableCell
} from 'grommet'
import { StatusGood } from 'grommet-icons'
import { CircleAlert } from 'grommet-icons'
import Navbar from '../common/Navbar'
import './Browse.css'
import Section from './Section'
import NoBooksCard from './NoBooksCard'
import {
  Link
} from 'react-router-dom'

import UserContext from 'common/UserContext'
import { makeApiRequest, completeImageUrl } from 'common/ApiUtils'
import { auth } from 'index'
import { onAuthStateChanged } from 'firebase/auth'
import LoadingCard from './LoadingCard'

const Browse = () => {
  const { user } = useContext(UserContext)

  const [query, setQuery] = useState('')
  const [allResults, setAllResults] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [areYourBooksAvailable, setAreYourBooksAvailble] = useState(false)
  const [areAllResultsAvailable, setAreAllResultsAvailable] = useState(false)

  const loadBooks = async () => {
    const response = await makeApiRequest('summarizeUsers', { })
    if (response.status === 200) {
      setAllResults(await response.json())
      setAreAllResultsAvailable(true)
    } else {
      console.log('Bad response!')
      console.log(response)
    }
    // const response = await makeApiRequest('getUserPublicProfile', { userId: 'yEvbMz0KcAT4DVcD9SODJRsR0hw1' })
    // if (response.status === 200) {
    //   const profile = (await response.json()).profile
    //   profile.photoUrls = await Promise.all(profile.photoUrls.map(async (url) => await completeImageUrl(url)))
    //   console.log(`Response: ${JSON.stringify(profile, undefined, 2)}`)
    // } else {
    //   console.log('Bad response!')
    //   console.log(response)
    // }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        loadBooks()
      }
    })
  }, [])

  function onReturn() {
    // TODO: Filter allResults
    if (query.trim() === '') {
      setSearchResults([])
    } else {
      setSearchResults(allResults.filter(result => JSON.stringify(result).toLowerCase().includes(query.toLowerCase())))
    }
  }

  useEffect(() => {
    if (query.trim() === '') {
      setSearchResults([])
    } else {
      setSearchResults(allResults.filter(result => JSON.stringify(result).toLowerCase().includes(query.toLowerCase())))
    }
  }, [query])

  function makeTable(results) {
    return  (
      <Box
        className='bookCard'
        flex={true}
      >
      <Table>
      <TableHeader>
        <TableRow>
          <TableCell scope="col" border="bottom">
            <strong>ID</strong>
          </TableCell>
          <TableCell scope="col" border="bottom">
            <strong>Display Name</strong>
          </TableCell>
          <TableCell scope="col" border="bottom">
            <strong>Content OK?</strong>
          </TableCell>
          <TableCell scope="col" border="bottom">
            <strong>Content Status</strong>
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {results.map(result => 
          <TableRow>
            <TableCell scope="row">
            <Link style={{ color: 'inherit', fontWeight: 'bold' }} className='routerLink' to={`/profile/${result.userId}`}>{result.userId}</Link>
            </TableCell>
            <TableCell>{result.displayName}</TableCell>
            <TableCell>{result.contentAcceptable ? <StatusGood /> : <CircleAlert />}</TableCell>
            <TableCell>{result.status}</TableCell>
          </TableRow>
          )}
      </TableBody>
    </Table>
    </Box>)
  }

  return (
    <Box background='light-2' pad={{ bottom: '16px' }} className='fade'>
      <Navbar query={query} setQuery={setQuery} onReturn={onReturn}/>
      <Box margin={{ top: '66px' }}>
        <Box>
          <Text
            className='browseSectionHeader'
          >
            {query.trim() === '' ? 'All users on your platform' : 'Search results'}
          </Text>
          <Box direction='row' wrap>
            {
              areAllResultsAvailable ? (
                query.trim() === '' ? makeTable(allResults) : makeTable(searchResults)
              )
               : (
                <LoadingCard />
              )
            }
          </Box>
        </Box>
        
      </Box>
    </Box>
  )
}

export default Browse

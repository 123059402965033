import React from 'react'

import {
  Box,
  Spinner,
  Text
} from 'grommet'

const LoadingCard = () => (
  <Box
    direction='row' className='bookCard'
    flex={false}
    gap={100}
  >
    <Box
      direction='column'
      flex={false}
    >
      <b><Text size='small'>Fetching user evaluations</Text></b>
      <Text size='small'>This may take a few seconds...</Text>
    </Box>
    <Spinner style={{ marginLeft: 25 }} />
  </Box>
)

export default LoadingCard

import React, { useEffect, useState } from 'react'

import '../Landing/Landing.css'
import { Box, Card, CardBody, Text, Spinner, Carousel, Image } from 'grommet'
import {
  useParams
} from 'react-router-dom'
import { makeApiRequest, completeImageUrl } from 'common/ApiUtils'

const Profile = () => {
  const { userId } = useParams()
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    (async () => {
      const response = await makeApiRequest('getUserPublicProfile', { userId })
      if (response.status === 200) {
        const profile = (await response.json()).profile
        profile.photoUrls = await Promise.all(profile.photoUrls.map(async (url) => await completeImageUrl(url)))
        console.log(JSON.stringify(profile))
        setProfile(profile)
      } else {
        console.log('Bad response!')
        console.log(response)
      }
    })()
  }, [])

  const mainContent = profile !== null && (
    <Box>
      <Text>
        <strong>{profile.name}, {profile.gender === 'MALE' ? 'M' : (profile.gender === 'FEMALE' ? 'F' : 'NB')}{profile.age}</strong>
      </Text>
      <Text size='small'>
        {profile.locationDisplayName}
      </Text>
      <Text size='small'>
        <em>Relationship status:</em> {profile.relationshipStatus.toLowerCase().replaceAll('_', ' ')}
      </Text>
      <br />
      <Text size='small'>
        <em>{profile.name}'s description:</em>
      </Text>
      <Text size='small'>
        {profile.description}
      </Text>
    </Box>
  )

  const images = profile === null ? null : (
    profile.photoUrls.length === 0 ? (<Text size='small'>No photos uploaded</Text>) : (
    <Carousel>
      {profile.photoUrls.map(url => (
        <Box pad="50px">
          <Image
            fit="cover"
            src={url}
          />
        </Box>
      ))}
    </Carousel>
    )
  )

  return (
    <Box fill>
      <Box
        width='medium'
        align='center'
        justify='center'
        gap='small'
        fill
      >
        <Card width='medium' background='white'>
          <CardBody pad='medium'>
            <Box direction='column' gap='small' fill>
              {profile === null ? <Spinner style={{ alignSelf: 'center' }} /> : mainContent}
            </Box>
          </CardBody>
        </Card>

        <Card width='medium' background='white'>
          <CardBody pad='medium'>
            <Box direction='column' gap='small' fill>
              {profile === null ? <Spinner style={{ alignSelf: 'center' }} /> : images}
            </Box>
          </CardBody>
        </Card>
      </Box>
    </Box>
  )
}

export default Profile

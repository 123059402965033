import React from 'react'

import * as Strings from 'common/strings'

import {
  Anchor,
  Card,
  CardBody,
  Text
} from 'grommet'

const BottomCard = () =>
  (
    <Card width='medium' background='white'>
      <CardBody pad='medium'>
        <Text size='small'>
          {Strings.about}<a style={{ textDecoration: 'none', color: 'inherit' }} href='https://www.moonrise.us'><b>{Strings.freeTrial}</b></a>{Strings.aboutContinued}
        </Text>
        <Text size='xsmall' className='copyrightText'>
          {Strings.copyright}
        </Text>
      </CardBody>
    </Card>
  )

export default BottomCard

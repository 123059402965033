const theme = {
  global: {
    colors: {
      brand: '#1D1D1D',
      focus: '#1D1D1D30'
    },
    font: {
      family: 'Montserrat',
      size: '14px',
      height: '20px'
    }
  },
  custom: {
    iconSize: '17px'
  },
  tip: {
    content: {
      background: 'white'
    }
  }
}
export default theme

import React from 'react'
import {
  Box
} from 'grommet'
import Navbar from '../common/Navbar'
import PasswordChangeForm from './PasswordChangeForm'

const Settings = () =>
  (
    <Box background='light-2' pad={{ bottom: '16px' }} className='fade' gap='medium'>
      <Navbar />
      <Box
        width='medium'
        align='center'
        justify='center'
        gap='small'
        fill
        margin={{ top: '66px' }}
      >
        <PasswordChangeForm />
      </Box>
    </Box>
  )

export default Settings
